import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueCryptojs from 'vue-cryptojs'

Vue.use(VueRouter)
Vue.use(VueCryptojs)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/material',
    name: 'Material',
    component: () => import('../views/Material.vue')
  },
  {
    path: '/apply',
    name: 'Apply',
    component: () => import('../views/Apply.vue')
  },
  {
    path: '/collectors',
    name: 'Collectors',
    component: () => import('../views/Collectors.vue')
  },
  {
    path: '/collectorDetail',
    name: 'CollectorsDetail',
    component: () => import('../views/CollectorsDetail.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/Shop.vue')
  },
  {
    path: '/productDetail',
    name: 'ProductDetail',
    component: () => import('../views/ProductDetail.vue')
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: () => import('../views/NewsDetail.vue')
  },
  {
    path: '/projectsDetail',
    name: 'ProjectsDetail',
    component: () => import('../views/ProjectsDetail.vue')
  },
  {
    path: '/pressDetail',
    name: 'PressDetail',
    component: () => import('../views/PressDetail.vue')
  },
  {
    path: '/basket',
    name: 'Basket',
    component: () => import('../views/Basket.vue')
  },
  {
    path: '/userForm',
    name: 'UserForm',
    component: () => import('../views/UserForm.vue')
  },
  {
    path: '/orderComplete',
    name: 'OrderComplete',
    component: () => import('../views/OrderComplete.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/press',
    name: 'Press',
    component: () => import('../views/Press.vue')
  },
  {
    path: '/instagram',
    name: 'Instagram',
    component: () => import('../views/Instagram.vue')
  },
  {
    path: '/sellers',
    name: 'Sellers',
    component: () => import('../views/Sellers.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: () => import('../views/Delivery.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/donate',
    name: 'Donate',
    component: () => import('../views/Donate.vue')
  },
  {
    path: '/tacs',
    name: 'Tac',
    component: () => import('../views/Tac.vue')
  },
  {
    path: '/revocation',
    name: 'Revocation',
    component: () => import('../views/Revocation.vue')
  },
  {
    path: '/donations',
    name: 'Donations',
    component: () => import('../views/Donations.vue')
  },
  {
    path: '/support',
    name: 'Supporters',
    component: () => import('../views/Supporters.vue')
  },
  {
    path: '/initiative',
    name: 'Initiative',
    component: () => import('../views/Initiative.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  
  //on live server base: '/',
  base: '/',
  
  //on live test-server base: '/shopptrview/',
  //base: '/shoppreview/',
  
  routes
})

export default router
