<template>
  <header>
    <div class="wrap">
      <div
        class="header__sbs-logo"
        v-bind:class="{ header__sbs_logo_small: scrollPosition > 10 }"
      >
        <router-link id="home" to="/">
          <img alt="SBS Logo" src="@/assets/images/second_bandshirt_logo.png" />
        </router-link>
      </div>
      <div
        v-if="!store.enableShop"
        class="header__shirt-icon header__shirt-icon_disabled"
      >
        <img alt="Shirt Icon" src="@/assets/images/icons/shirt_icon.png" />
        <span
          v-bind:class="{ pop_shop_animation: shopCardIsChanged }"
          class="header__shirt-icon__counter"
          >{{ store.userData.products.length }}</span
        >
      </div>
      <div
        v-if="store.enableShop"
        v-on:click="gotoBasket()"
        class="header__shirt-icon"
      >
        <img alt="Shirt Icon" src="@/assets/images/icons/shirt_icon.png" />
        <span
          v-bind:class="{ pop_shop_animation: shopCardIsChanged }"
          class="header__shirt-icon__counter"
          >{{ store.userData.products.length }}</span
        >
      </div>

      <div id="hamburger" v-on:click="display_menu()">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <nav id="menu">
        <div class="menu__left">
          <li v-on:click="close_all_menu()">
            <router-link id="home" to="/">
              <span>Home</span>
            </router-link>
          </li>
          <li v-on:click="close_all_menu()">
            <router-link id="shop" to="/shop">
              <span>Shop</span>
            </router-link>
          </li>
          <li v-on:click="close_all_menu()">
            <router-link id="sellers" to="/sellers">
              <span>Verkaufsstände</span>
            </router-link>
          </li>
          <li v-on:click="close_all_menu()">
            <router-link id="collectors" to="/collectors">
              <span>Ortsgruppen</span>
            </router-link>
          </li>
        </div>

        <div class="menu__right">
          <li v-on:click="close_all_menu()">
            <router-link id="aktuelles" to="/news">
              <span>Aktuelles</span>
            </router-link>
          </li>
          <li class="drop">
            <a class="drop_link" v-on:click="display_drop_menu"
              >Infos <i class="icon-arrow"></i
            ></a>
            <ul class="drop_menu">
              <li v-on:click="close_all_menu()">
                <router-link id="projekte" to="/projects">
                  <span>Projekte</span>
                </router-link>
              </li>
              <li v-on:click="close_all_menu()">
                <router-link id="press" to="/press">
                  <span>Presse</span>
                </router-link>
              </li>
              <li v-on:click="close_all_menu()">
                <router-link id="instagram" to="/instagram">
                  <span>Instagram</span>
                </router-link>
              </li>
              <li v-on:click="close_all_menu()">
                <router-link id="about" to="/about">
                  <span>Über uns</span>
                </router-link>
              </li>
              <li v-on:click="close_all_menu()">
                <router-link id="initiative" to="/initiative">
                  <span>ITZ</span>
                </router-link>
              </li>
              <li v-on:click="close_all_menu()">
                <router-link id="material" to="/material">
                  <span>Material</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="drop">
            <a class="drop_link" v-on:click="display_drop_menu"
              >Kontakt <i class="icon-arrow"></i
            ></a>
            <ul class="drop_menu drop_menu_last">
              <li v-on:click="close_all_menu()">
                <router-link id="donate" to="/donate">
                  <span>Shirts Spenden</span>
                </router-link>
              </li>
              <li v-on:click="close_all_menu()">
                <router-link id="contact" to="/contact">
                  <span>Kontaktadressen</span>
                </router-link>
              </li>
              <li v-on:click="close_all_menu()">
                <router-link id="apply" to="/apply">
                  <span>Mitmachen</span>
                </router-link>
              </li>
            </ul>
          </li>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import store from "@/store/store.js";

export default {
  name: "Header",
  components: {},
  data() {
    return {
      scrollPosition: 0,
      store,
      shopCardIsChanged: false,
    };
  },

  methods: {
    onResize() {
      //this.close_all_menu();
      //document.getElementsByTagName("body")[0].classList.remove("display_menu");
    },

    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    gotoBasket() {
      this.$router.push("basket");
    },
    display_menu: function () {
      var body = document.getElementsByTagName("body")[0];
      !body.classList.contains("display_menu")
        ? body.classList.add("display_menu")
        : body.classList.remove("display_menu");
    },
    display_drop_menu: function (event) {
      var drop_menu =
        event.target.parentElement.getElementsByClassName("drop_menu")[0];
      var drop_menus = document.getElementsByClassName("drop_menu");

      Array.from(drop_menus).forEach(function (e) {
        if (e != drop_menu) {
          e.classList.remove("display");
        }
      });
      var lis = document.getElementById("menu").getElementsByTagName("li");
      Array.from(lis).forEach(function (e) {
        e.style.marginTop = 0;
      });
      !drop_menu.classList.contains("display")
        ? drop_menu.classList.add("display")
        : drop_menu.classList.remove("display");
      if (window.innerWidth < 1024 && drop_menu.classList.contains("display")) {
        if (event.target.parentElement.nextSibling != null) {
          event.target.parentElement.nextSibling.style.marginTop =
            drop_menu.clientHeight + "px";
        }
      }
    },
    close_all_menu() {
      var check = true;
      /*
      if (!this.checkMobileDevice() || enableOnMobile) {
        check = true;
      }
      */

      if (check) {
        var lis = document.getElementById("menu").getElementsByTagName("li");
        Array.from(lis).forEach(function (e) {
          e.style.marginTop = 0;
        });
        var drop_menus = document.getElementsByClassName("drop_menu");
        Array.from(drop_menus).forEach(function (e) {
          e.classList.remove("display");
        });

        var body = document.getElementsByTagName("body")[0];
        !body.classList.contains("display_menu")
          ? body.classList.add("display_menu")
          : body.classList.remove("display_menu");
      }
    },
    checkMobileDevice() {
      var isMobile = false;
      // device detection
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        isMobile = true;
      } else {
        isMobile = false;
      }

      console.log(isMobile);

      return isMobile;
    },
  },

  computed: {
    productsCount() {
      return this.store.userData.products.length;
    },
  },
  watch: {
    productsCount() {
      var self = this;
      self.shopCardIsChanged = true;
      setTimeout(function () {
        self.shopCardIsChanged = false;
      }, 2000);
    },
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.onResize);
  },

  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$body_height_8: calc((100vh - 113px) / 8);
$body_height_4: calc((100vh - 113px) / 4);
.drop_link {
  cursor: pointer;
  padding-left: 20px!important;
}
.header__sbs-logo {
  position: absolute;
  left: 50%;
  top: 16px;
  margin-left: -42px;
  transition: all 0.3s;
  z-index: 1000;
  img {
    width: 89px;
  }
}
.header__sbs_logo_small {
  top: -16px;
  transform: scale(0.5);
}
.header__shirt-icon {
  position: absolute;
  right: 16px;
  top: 24px;
  cursor: pointer;
  img {
    width: 24px;
  }
  &_disabled {
    opacity: 0.2;
  }
  &__counter {
    background-color: $color-primary;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: $color-white;
    display: block;
    position: absolute;
    left: -10px;
    top: -5px;
    font-size: 10px;
    line-height: 17px;
  }
}

header {
  display: flex;
  z-index: 1;
  padding: 0;
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  justify-content: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: 0.33s;
  z-index: 100;
}
header .wrap {
  position: relative;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 1310px;
}
.icon-arrow {
  mask: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 284.929 284.929'><path d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441		L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'/></svg>");
  pointer-events: none;
}
i {
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
}
header img {
  width: 100px;
}
header #menu {
  display: flex;
  flex-direction: row;
}
header #menu li {
  position: relative;
  user-select: none;
  margin-left: 10px;
  list-style: none;
  i {
    width: 12px;
    height: 12px;
    background-color: grey;
  }
  a {
    font-weight: normal;
    font-family: "Bebas Neue", sans-serif;
    color: black;
    display: inline-block;
    text-decoration: none;
    transition: all, 0.3s ease-in-out;

    font-size: 27px;
    padding: 5px;
    line-height: 49px;
    &:hover {
      color: $color-primary;
    }
  }
}
header .drop_menu {
  position: absolute;
  display: block;
  transform: scaleY(0);
  width: auto;
  transform-origin: top;
  background-color: white;
  transition: 0.25s;
  padding-bottom: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 0;
  top: 50px;
  a {
    margin-left: 20px;
    display: block;
    transition: 0.45s;
    opacity: 0;
    margin-right: 20px;
    padding: 10px 0 0 0;
  }
  li {
    margin-left: 0 !important;
    a {
      font-size: 20px !important;
      padding: 0px !important;
      line-height: 40px !important;
    }
  }
}

header .drop_menu.display {
  transform: scaleY(1);
  a {
    opacity: 1;
  }
}
header .drop_menu_last.display {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}
header #hamburger {
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  left: 3%;
  top: 30px;
  display: none;
  transform: translateY(-50%);
}
header #hamburger span {
  height: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: grey;
  display: block;
  transition: 0.33s;
  &:nth-child(1) {
    width: 12px;
  }
  &:nth-child(2) {
    width: 24px;
  }
  &:nth-child(3) {
    width: 12px;
  }
}
.display_menu header #hamburger {
  span:nth-child(1) {
    transform: rotate(45deg) translate(2px, 1px);
  }
  span:nth-child(2) {
    transform: rotate(-45deg);
  }
  span:nth-child(3) {
    transform: rotate(45deg) translate(6px, -9px);
  }
}

@media screen and (max-width: 1024px) {
  header .drop_menu li a {
    line-height: $body_height_8 !important;
  }
  header {
    position: absolute;
  }
  .drop {
    cursor: pointer !important;
  }
  body {
    padding-top: 70px;
  }
  header .wrap {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
  }
  header img {
    width: 50px;
  }
  header #hamburger {
    display: block;
  }
  header #menu {
    width: 100%;
    display: block;
    height: 0;
    transform-origin: 50% 0;
    transition: 0.33s ease;
    flex-direction: column;
    margin-top: 60px;
  }
  .display_menu header #menu {
    height: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    li {
      height: $body_height_8;
      border-bottom: 1px solid grey;
      transition: 0.25s ease;
      opacity: 1;
      display: block;
    }
  }
  header #menu li {
    height: 0;
    opacity: 0;
    margin-left: 0;
    transition: 0.25s ease;
    a {
      left: 0;
      line-height: $body_height_8;
      padding: 0;
      border: none;
      height: 100%;
      width: auto;
      display: block;

      span{
        padding-left: 20px;
      }

      &:hover {
        color: white;
        background-color: $color-primary;
        i {
          background-color: white;
        }
      }
    }
    i {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      background-color: grey;
    }
  }
  header .drop_menu {
    top: $body_height_8;
    box-shadow: none;
    left: 0;
    padding-bottom: 0;
    width: 100%;
    margin-top: 1px;
    a {
      width: 100%;
      margin: 0;
      border-bottom: 1px solid grey !important;
      height: 70px;
      span {
        padding-left: 20px;
      }
    }
  }
}
@media screen and(max-width: 1024px) and(max-height: 500px) {
  .display_menu header {
    max-height: 100vh;
    overflow-y: scroll;
  }
  .display_menu header #menu {
    li {
      height: $body_height_4;
    }
  }
  header #menu li {
    a {
      line-height: $body_height_4;
    }
  }
  header .drop_menu {
    top: $body_height_4;
    li {
      a {
        line-height: $body_height_8;
      }
    }
  }
}
i {
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
}
#menu {
  @media screen and (min-width: 1024px) {
    width: 100%;
  }
}
.menu__left {
  display: contents;
  @media screen and (min-width: 1024px) {
    display: inline-flex;
  }
}
.menu__right {
  position: relative;
  display: contents;
  @media screen and (min-width: 1024px) {
    display: inline-flex;
    position: absolute;
    right: 70px;
  }
}
</style>
