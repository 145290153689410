<template>
  <div v-if="store.contentData" class="overallpage">
    <h2>{{getContentData('transparentezivilgesellschaft').headline}}</h2>
    <span v-html="getContentData('transparentezivilgesellschaft').pageContent"></span>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'Transparentezivilgesellschaft',
  metaInfo: {
    title: 'Second Bandshirt - Transparentezivilgesellschaft',
    htmlAttrs: {
      lang: 'de',
      amp: true
    }
  },
  components: {
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    getContentData: function (dataHandle) {
      var contentModel = null;
      for(var i = 0; i < this.store.contentData.length; i++){
        if(this.store.contentData[i].pageHandle == dataHandle){
          contentModel = this.store.contentData[i];
        }
      }
      return contentModel;
    },
    openPage(url){
      this.$router.push(url);
    },
  }
}
</script>

<style lang="scss">
  
</style>
